import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";

import config from "../../config/SiteConfig";
import { Layout, Wrapper, Button, Article, GridRow, Homepage, HomepageContent } from "../components";
import { PageProps } from "../models";

export default function IndexPage({ data }: PageProps) {
    const { edges, totalCount } = data.allMarkdownRemark;
    return (
        <Layout>
            <Wrapper fullWidth={true}>
                <Helmet title={`Homepage | ${config.siteTitle}`} />
                <Homepage>
                    <GridRow background={true}>
                        <HomepageContent center={true}>
                            <img style={{ borderRadius: "50%" }} src={config.siteLogo} />
                            <h1>
                                Hi. I am <br />
                                Tyler Robinson
                            </h1>
                            <p>
                                I'm a developer of many flavors. You'll find musing of Android, Web, and other types of development here :)
                            </p>
                            <Link to="/contact">
                                <Button big>Contact</Button>
                            </Link>
                            <Link to="/blog">
                                <Button big>Blog</Button>
                            </Link>
                        </HomepageContent>
                    </GridRow>
                    <GridRow>
                        <HomepageContent>
                            <h3>About Me</h3>
                            <p>
                                Hey! I'm a software developer, as you may have guessed. Many years ago, I started an Android development
                                business called DizWARE with a successful app called ShuffleTone. I've since done most of my public solo
                                work under the DizWARE moniker. I've taken on a career of full-stack web development, in technologies like
                                Angular, React, ASP.NET, .NET Core, Docker, Microservice Architecture, and Electron. I've also worked on
                                solo projects with technologies like Flutter, Xamarin, and NativeScript. I love learning and am constantly
                                digging into new technolgies when I can.
                            </p>
                            <hr />
                            <h3>Latest Blog</h3>
                            {edges.map(post => (
                                <Article
                                    title={post.node.frontmatter.title}
                                    date={post.node.frontmatter.date}
                                    excerpt={post.node.excerpt}
                                    timeToRead={post.node.timeToRead}
                                    slug={post.node.fields.slug}
                                    category={post.node.frontmatter.category}
                                    key={post.node.fields.slug}
                                />
                            ))}
                            <p className={"textRight"}>
                                <Link to={"/blog"}>All articles ({totalCount})</Link>
                            </p>
                        </HomepageContent>
                    </GridRow>
                </Homepage>
            </Wrapper>
        </Layout>
    );
}

export const IndexQuery = graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD.MM.YYYY")
                        category
                    }
                    timeToRead
                }
            }
        }
    }
`;
